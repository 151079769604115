@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quando&display=swap');

@font-face {
  font-display: swap;
}

html {
  font-size: 62.5%;
  background: linear-gradient(120deg, #bb3139b8 0%, #ffced600 150%);
}

body {
  border: 10px solid #fff;
  background: #fff;
  font-family: 'Quando', serif;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #cc2e37;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #525252;
}

h1 {
  font-family: 'Quando', serif;
  font-size: 6rem;
}

h2 {
  font-size: 2.6rem;
}

h3 {
  font-family: 'Caveat', cursive;
  font-size: 4rem;
  margin-top: 3em;
  color: #032b2f;
}

button {
  font-family: 'Quando', serif;
  letter-spacing: 1.2px;
}

input,
textarea {
  font-family: 'Quando', serif;
}

.menu__icon {
  height: 40px;
  width: 30px;
  margin-bottom: -5px;
  margin-right: 10px;
}
